import * as Herz from '@rushplay/herz'
import * as Pwa from './pwa'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRouterDom from 'react-router-dom'
import * as ThemeUi from 'theme-ui'
import * as Urql from 'urql'

import {Image} from './image'
import {Logotype} from './logotype'

const FooterSectionsQuery = `
  query FooterSectionsQuery {
    configuration {
      footerSections {
        gameProviders {
          image
          name
        }
      }
    }
  }
`

export function Footer() {
  const [response] = Urql.useQuery({query: FooterSectionsQuery})
  const pwa = Pwa.useContext()
  const translate = Herz.I18n.useTranslate()

  const gameProviders = R.pathOr(
    [],
    ['data', 'configuration', 'footerSections', 'gameProviders'],
    response
  )

  return (
    <ThemeUi.Box
      as="footer"
      sx={{
        backgroundColor: 'footer-background',
        color: 'static-white',
        py: [4, 6],
        px: 2,
      }}
    >
      <ThemeUi.Box
        sx={{
          mx: 'auto',
          width: '100%',
          maxWidth: 'container.lg',
          display: 'grid',
          justifyItems: 'center',
        }}
      >
        <Logotype />
        <ThemeUi.Box
          as="nav"
          sx={{
            py: 3,
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          <ThemeUi.Link
            to="/how-to-play"
            as={ReactRouterDom.Link}
            variant="footerNav"
          >
            {translate('footer.how-to-play')}
          </ThemeUi.Link>
          <ThemeUi.Link
            href="mailto:support@casitabifree.com"
            variant="footerNav"
          >
            {translate('footer.contact-us')}
          </ThemeUi.Link>
          <ThemeUi.Link
            as={ReactRouterDom.Link}
            to="/terms-and-conditions"
            variant="footerNav"
          >
            {translate('footer.terms-and-conditions')}
          </ThemeUi.Link>
          <ThemeUi.Link
            as={ReactRouterDom.Link}
            to="/privacy-policy"
            variant="footerNav"
          >
            {translate('footer.privacy-policy')}
          </ThemeUi.Link>
          {pwa.visible && (
            <ThemeUi.Link variant="footerNav" onClick={pwa.onInstall}>
              {translate('pwa.install')}
            </ThemeUi.Link>
          )}
        </ThemeUi.Box>
        <ThemeUi.Box
          sx={{
            display: 'flex',
            color: 'static-white',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 0,
          }}
        >
          <Image
            source={translate('18.plus.icon')}
            width="100"
            height="24"
            imgProxyBucket="babel-fisk"
            sx={{
              height: '30px',
              width: 'auto',
              m: 1,
            }}
          />
          {translate('footer.play.responsibly')}
        </ThemeUi.Box>
        <ThemeUi.Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            opacity: 0.5,
            justifyContent: 'center',
          }}
        >
          {gameProviders.map((provider) => (
            <ThemeUi.Image
              key={provider.name}
              alt={provider.name}
              src={provider.image}
              height="24"
              width="100"
              sx={{
                height: '24px',
                width: 'auto',
                m: 2,
              }}
            />
          ))}
        </ThemeUi.Box>
      </ThemeUi.Box>
    </ThemeUi.Box>
  )
}

Herz.I18n.Loader.preload(
  [
    '18.plus.icon',
    'footer.how-to-play',
    'footer.contact-us',
    'footer.play.responsibly',
    'footer.privacy-policy',
    'footer.terms-and-conditions',
    'pwa.install',
  ],
  Footer
)

export default Footer
